import {
  AboutUs,

  
} from "../components/common/page-componets";

const About = () => {
  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <AboutUs />
   
     
    
    </div>
  );
};

export default About;
