import { FiCheck, FiLayers, FiUsers } from "react-icons/fi";

const Speciality = () => {
  return (
    <div className="pt-10 pb-16">
      <div className="flex flex-wrap gap-10">
        <div className="flex-1 basis-[20rem]">
          <h1 className="sub-heading">à propos de nous</h1>
          <h1 className="heading">
            nous sommes spécialisés dans les appartements de qualité
          </h1>
          <p className="mt-3">
            Bienvenue chez Assakina, votre destination de choix pour des
            locations d'appartements de haut standing. Chez Assakina, nous
            sommes dévoués à vous offrir des espaces de vie exceptionnels qui
            redéfinissent le confort, le luxe et la commodité. Notre engagement
            envers l'excellence a fait de nous un nom de confiance dans
            l'industrie immobilière, et nous sommes ravis de nous présenter à
            vous.
          </p>
          <h1 className="sub-heading">Notre Histoire</h1>
          <p className="mt-3">
            Assakina a été fondée avec une vision singulière : transformer la
            manière dont les gens vivent l'expérience de l'appartement. Notre
            voyage a débuté avec une passion pour la qualité et le désir de
            créer des communautés où les individus et les familles peuvent
            s'épanouir. Au fil des années, nous avons perfectionné nos
            compétences, affiné notre approche et développé notre portefeuille
            pour offrir une gamme diversifiée d'appartements qui répondent à
            différents goûts et modes de vie.
          </p>
          <h1 className="sub-heading">Notre Mision</h1>
          <p className="mt-3">
            Chez Assakina, notre mission est claire : fournir des appartements
            de qualité qui dépassent vos attentes. Nous croyons que votre
            domicile n'est pas seulement un lieu de vie ; c'est le reflet de
            votre style de vie et de vos aspirations. C'est pourquoi nous nous
            engageons à offrir des appartements soigneusement conçus et bien
            entretenus dans des emplacements privilégiés pour vous garantir le
            meilleur des deux mondes : un chez-vous confortable et accueillant,
            ainsi qu'une communauté vivante et accessible.
          </p>
          <h1 className="sub-heading">Ce Qui Nous Distingue</h1>
          <div className="mt-4">
            <div className="flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <p>Propriété exceptionnelle</p>
            </div>
            <div className="mt-2 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <p>Qualité Intransigeante</p>
            </div>
            <div className="mt-2 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <p> Service Client Réactif </p>
            </div>
            <div className="mt-2 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <p>Équipements d'Exception </p>
            </div>
            <div className="mt-2 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <p>Durabilité</p>
            </div>
            
          </div>
        </div>
        <div className="flex-1 basis-[20rem]">
          <div className="relative">
            <img
              src="/images/property (5).jpg"
              alt=""
              className="rounded-lg w-full sm:h-[400px] object-cover"
            />
            <div className="absolute -bottom-10 sm:bottom-5 -left-2 md:-left-20">
              <div className="p-3 bg-white rounded-lg shadow-md w-72 flex-center-between gap-x-3 dark:bg-dark-light">
                <h1>Nous avons toujours fourni à nos clients un service exceptionnel.</h1>
                <div className="icon-box text-primary !bg-primary/20">
                  <FiUsers />
                </div>
              </div>
              <div className="p-3 mt-4 ml-8 bg-white rounded-lg shadow-md w-72 flex-center-between gap-x-3 dark:bg-dark-light">
                <h1>
                Travailler avec le symbole et la réputation d'un trait de confiance
                </h1>
                <div className="icon-box text-primary !bg-primary/20">
                  <FiLayers />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Speciality;
