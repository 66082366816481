export const navLinks = [
  {
    id: 1,
    linkText: "Home",
    url: "/",
    
  },
  {
    id: 2,
    linkText: "À Propos De Nous",
    url: "/about-us",
   
  },
  {
    id: 3,
    linkText: "Services",
    url: "/services",
    
  },
 
 
  {
    id: 6,
    linkText: "Contact",
    url: "/contact",
  },
  {
    id: 7,
    linkText: "Faq",
    url: "/faqs",
    
  },
];
