/* eslint-disable jsx-a11y/anchor-is-valid */

import { FaInstagram } from "react-icons/fa";
import { FiFacebook,FiYoutube } from "react-icons/fi";
import { Link } from "react-router-dom";
import ContactInfo from "../contact/ContactInfo";

const Footer = () => {
  return (
    <div className="text-slate-200">
      <footer>
        <div className="flex flex-wrap gap-2">
          <div className="flex-1 basis-[10rem]">
            <Link to="/" className="flex-shrink-0 flex-align-center gap-x-1">
              <img
                src="/images/assakina.png"
                className="text-3xl text-primary "
                style={{ width: "100px" }}
                alt="logo"
              />
              <h1 className="hidden md:block">Assakina</h1>
            </Link>
            <div className="mt-3">
              <p className="text-sm">
                Chez Assakina Real Estate, notre vision est d'être le principal
                fournisseur de solutions immobilières exceptionnelles, créant
                des opportunités et transformant des vies.
              </p>
              <div className="gap-5 my-6 flex-center-center">
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a href="https://www.facebook.com" target="_blank"rel="noreferrer">
                    <FiFacebook />
                  </a>
                </div>

                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a href="https://www.instagram.com" target="_blank"rel="noreferrer">
                    {" "}
                    <FaInstagram />{" "}
                  </a>
                </div>

                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a
                    href="https://www.youtube.com/channel/UCgWUB_IhWhNagt247nGZWjA"
                    target="_blank"
                    rel="noreferrer"
                  >
                  
                    <FiYoutube/>
                  </a>
                 
                </div>
              </div>
            </div>
          </div>

    {      <div className="flex-1 basis-[10rem]">
            <ContactInfo/>
          </div>}

    

        
        </div>
      </footer>
      <div className="py-2 mt-3 text-center border-t text-muted border-dark">
        <p>
          <span className="text-primary">Assakina</span> | All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
