const Hero = () => {
  return (
    <div
      className="relative z-0 flex-wrap min-h-screen gap-2 md:-mt-10 flex-center-center"
      style={{
        background: "url('/images/hero-bg-pattern.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <div className="absolute top-0 right-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>
      <div className="flex-1 basis-[20rem]">
        <h1 className="text-4xl font-bold capitalize md:text-5xl">
        Bienvenu à <br /> Assakina.ma
        </h1>
        <div className="pl-3 mt-5 border-l-4 border-primary">
          <p>
          Découvrez une sélection exceptionnelle d'appartements à vendre. Que vous soyez à la recherche d'un appartement moderne en centre-ville ou d'un penthouse avec vue imprenable, nous avons des propriétés qui répondront à vos critères. Parcourez nos annonces, explorez les photos et descriptions détaillées, et trouvez l'appartement idéal qui correspond à vos besoins et à votre style de vie.

Faites confiance à notre expertise en immobilier et trouvez dès maintenant votre nouvelle résidence parmi nos offres exclusives.
          </p>
        </div>
        
        
       
      </div>
      <div className="flex-1 basis-[20rem]">
        <img src="/images/hero-4.png" alt="" className="w-full" />
      </div>
    </div>
  );
};

export default Hero;
