import {  BiGlobe} from "react-icons/bi";

const AboutUs = () => {
  return (
    <div className="pt-16 pb-20">
      <div className="flex flex-wrap gap-24">
        <div className="relative flex-1 basis-[18rem] border">
          <img
            src="/images/property (16).jpg"
            alt=""
            className="object-cover w-full h-full rounded-lg"
          />
          <img
            src="/images/assakinab4.jpg"
            alt=""
            className="absolute object-cover w-48 h-64 border-4 border-white rounded-lg sm:w-72 sm:h-80 dark:border-dark -bottom-20 -right-2 md:-right-20"
          />
        </div>
        <div className="relative flex-1 basis-[22rem]">
          <h1 className="sub-heading">about us</h1>
          <h1 className="heading">NOTRE HISTOIRE</h1>
          <p className="mt-3">
            Assakina a été fondée avec une vision singulière : transformer la
            manière dont les gens vivent l'expérience de l'appartement. Notre
            voyage a débuté avec une passion pour la qualité et le désir de
            créer des communautés où les individus et les familles peuvent
            s'épanouir. Au fil des années, nous avons perfectionné nos
            compétences, affiné notre approche et développé notre portefeuille
            pour offrir une gamme diversifiée d'appartements qui répondent à
            différents goûts et modes de vie.
          </p>
          <div className="mt-4">
            <div className="flex-align-center gap-x-2">
             
            </div>

            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiGlobe />
              </div>
              <div>
                <h1 className="font-semibold capitalize">NOTRE MISION</h1>
                <p>
                  Chez Assakina, notre mission est claire : fournir des
                  appartements de qualité qui dépassent vos attentes. Nous
                  croyons que votre domicile n'est pas seulement un lieu de vie
                  ; c'est le reflet de votre style de vie et de vos aspirations.
                  C'est pourquoi nous nous engageons à offrir des appartements
                  soigneusement conçus et bien entretenus dans des emplacements
                  privilégiés pour vous garantir le meilleur des deux mondes :
                  un chez-vous confortable et accueillant, ainsi qu'une
                  communauté vivante et accessible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
