import {
  

  Featured,
  Services,

  /*<WhatWedo />*/
} from "../components/common/page-componets";
import { Hero, Speciality } from "../components/home/home-1";

const Home = () => {
  return (
    <div className="pt-16 px-[3%] md:px-[6%]">
      <Hero />
      <Speciality />
      {/*<WhatWedo />*/}
      <Services />
      <Featured />
      {/*<Brands />*/}
    </div>
  );
};

export default Home;
